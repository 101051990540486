import {AssessmentType} from '@nit-core/global/domain/enums';
import {Grading} from './nush-rule';
import {GroupedArray} from '@nit-core/global/domain/grouped-array';
import {CustomJournalDay} from 'src/app/private/pages/basic-data/pages/class-journal/components/journal-table/journal-table.component';
import {AcademicSemesterBounds} from './new-academic-year';

export class GeneralAchievement {
  id: string;
  classId: string;
  finalMarkType: AssessmentType;
  domainId?: string;
  name: string;
  scheduleId: string;
  subject: string;
  transferId: string;
  grading: Grading;
  userId: string;
  readOnly?: boolean;

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.grading = item.grading ? new Grading(item.grading) : null;
  }
}

export class Achievement {
  columns: AchievementColumn[];
  firstSemesterMark: ResultingAchievementMark;
  secondSemesterMark: ResultingAchievementMark;
  yearMark: ResultingAchievementMark;
  readOnly?: boolean;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class ResultingAchievementMark {
  id: string;
  learningOutcomeId: string;
  rating: number;
  customMark: number;
  leveledMark: number;
  isVerbalFormed: boolean;
  leveledAssessmentType?: number;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class AchievementColumn {
  id: string;
  name: string;
  marks: ResultingAchievementMark[];
  groupedMarks: GroupedArray<ResultingAchievementMark>;

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.marks = item.marks ? item.marks.map(mark => new ResultingAchievementMark(mark)) : [];
    this.groupedMarks = item.groupedMarks ?
      new GroupedArray<ResultingAchievementMark>(this.marks, ['learningOutcomeId']) : null;
  }
}

export class ColumnCreationParams {
  text?: string;
  columnId: string;
  journalDay: CustomJournalDay;
  journalDayDate?: string;
  journalType: number;
  journalId?: string;
  journalIndex?: number;
  pageType?: 'thematic' | 'summary' | 'calculation';
  semesterNumber?: 'I' | 'II';
  classId?: string;
  isEdit?: boolean;
  isThematic?: boolean;
  currentSchedule?: AcademicSemesterBounds;
  initialFromDate?: Date;
  initialToDate?: Date;
  firstLessonDate?: string;
  thematicDates?: string[] = [];
  summaryDates?: string[] = [];
  hasCompetences?: boolean;

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.journalDay = item.journalDay ? new CustomJournalDay(item.journalDay) : null;
    this.currentSchedule = item.currentSchedule ? new AcademicSemesterBounds(item.currentSchedule) : null;
  }
}
