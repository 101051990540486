import {Pipe, PipeTransform} from '@angular/core';

// Angular incorrectly handles value like 31/12/23 therefore need in this pipe arose
@Pipe({
  name: 'dateWithDots'
})
export class DateWithDotsPipe implements PipeTransform {
  transform(value: string | Date): string {
    const stringifiedValue = typeof value === 'string' ? value : value.toISOString();
    const [year, month, day] = stringifiedValue.split('T')[0].split('-');

    return `${day}.${month}.${year}`;
  }
}
