import {JournalColumnType} from '@nit-core/global/domain/enums';
import {ChildJournalSubject} from '@nit-core/models/child-journal';
import {AverageMarkSettings} from '@nit-core/models/journal';

export class JournalDay {
  id?: string;
  date: Date;
  index: number;
  type?: JournalColumnType;
  isDelete?: boolean;
  isCustomLesson?: boolean;
  teacherId?: string;
  journalIndex?: string;
  journalId?: string;
  journalTeacherId?: string;
  journalTeacherShortName?: string;
  lesson?: number;
  name: string;
  averageColumnId: string;
  fullName?: string;
  averageMarkSettings?: AverageMarkSettings;
  columnId?: string;
  subjects?: ChildJournalSubject[];
  columnType: JournalColumnType;
  academicYearId?: string;
  scheduleId?: string;
  classId?: string;
  classGroupId?: string;
  subject?: string;
  classroom?: string;
  classLetter?: string;
  className?: string;
  classNumber?: string;
  classGroupName?: string;
  isFds?: boolean;
  customThematicName?: boolean;
  includeInDecision?: boolean;
  competenceId?: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.date = item?.date ? new Date(item.date) : null;
    this.averageMarkSettings = item?.averageMarkSettings ? new AverageMarkSettings(item.averageMarkSettings) : null;
  }
}

export class JournalDayDeletedResponse {
  isJournalDeleted: boolean;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class JournalDayThematicRangeResponse {
  from: Date;
  to: Date;

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.from = item?.from ? new Date(item.date) : null;
    this.to = item?.to ? new Date(item.to) : null;
  }
}

export class Days {
  [key: number]: DayInfo;
  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class DayInfo {
  full: string;
  short: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class BlurredInfo {
  [key: string]: boolean;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class BatchCompetences {
  journalId: string;
  competenceIds: string;
  columnId: string;
  wholeSemester: boolean;
  to: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}
