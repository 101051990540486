<div #addMark id="add-mark" (click)="$event.stopPropagation()">
  <div class="container-checkbox" [class.right-end]="isRightEnd">
    <ng-container *ngIf="!data.isBlurred">
      <ng-container *ngIf="!isOpenEditModal && !isGradingForbidden && isDigitalAllowed">
        <div *ngIf="journalTableService.notCertified" class="small-block">
          <nit-field-wrapper>
            <label class="checkbox-horizontal">
              <input type="checkbox"
                      [checked]="customNotCertified"
                      (change)="checkCustomMark($event, 1)">
              <span>н/а</span>
            </label>
          </nit-field-wrapper>
        </div>

        <div *ngIf="journalTableService.liberated" class="small-block">
          <nit-field-wrapper>
            <label class="checkbox-horizontal">
              <input type="checkbox"
                      [checked]="customReleased"
                      (change)="checkCustomMark($event, 0)">
              <span>зв.</span>
            </label>
          </nit-field-wrapper>
        </div>

        <div *ngIf="journalTableService.notRated" class="small-block">
          <nit-field-wrapper [control]="form.get('absense')">
            <label class="checkbox-horizontal">
              <input type="checkbox"
                      [checked]="customNotRated"
                      (change)="checkCustomMark($event, 4)">
              <span>н/о</span>
            </label>
          </nit-field-wrapper>
        </div>

        <div *ngIf="journalTableService.credited" class="small-block">
          <nit-field-wrapper>
            <label class="checkbox-horizontal">
              <input type="checkbox"
                      [checked]="customAccepted"
                      (change)="checkCustomMark($event, 2)">
              <span>зарах.</span>
            </label>
          </nit-field-wrapper>
        </div>

        <div *ngIf="journalTableService.notAccepted" class="small-block">
          <nit-field-wrapper>
            <label class="checkbox-horizontal">
              <input type="checkbox"
                      [checked]="customNotAccepted"
                      (change)="checkCustomMark($event, 5)">
              <span>не зарах.</span>
            </label>
          </nit-field-wrapper>
        </div>

        <div *ngIf="journalTableService.studied" class="small-block">
          <nit-field-wrapper>
            <label class="checkbox-horizontal">
              <input type="checkbox"
                      [checked]="customLearned"
                      (change)="checkCustomMark($event, 3)">
              <span>вивч.</span>
            </label>
          </nit-field-wrapper>
        </div>
      </ng-container>

      <ng-container *ngIf="!isOpenEditModal && !isGradingForbidden && isLeveledAllowed">
        <div class="small-block">
          <nit-field-wrapper>
            <label class="checkbox-horizontal">
              <input type="checkbox"
                      [checked]="form.get('leveledMark').value === 0"
                      (change)="checkNushMark($event, 0, true)">
              <span class="nush-mark">П</span>
            </label>
          </nit-field-wrapper>
        </div>

        <div class="small-block">
          <nit-field-wrapper>
            <label class="checkbox-horizontal">
              <input type="checkbox"
                      [checked]="form.get('leveledMark').value === 1"
                      (change)="checkNushMark($event, 1, true)">
              <span class="nush-mark">С</span>
            </label>
          </nit-field-wrapper>
        </div>

        <div class="small-block">
          <nit-field-wrapper>
            <label class="checkbox-horizontal">
              <input type="checkbox"
                      [checked]="form.get('leveledMark').value === 2"
                      (change)="checkNushMark($event, 2, true)">
              <span class="nush-mark">Д</span>
            </label>
          </nit-field-wrapper>
        </div>

        <div class="small-block">
          <nit-field-wrapper>
            <label class="checkbox-horizontal">
              <input type="checkbox"
                      [checked]="form.get('leveledMark').value === 3"
                      (change)="checkNushMark($event, 3, true)">
              <span class="nush-mark">В</span>
            </label>
          </nit-field-wrapper>
        </div>
      </ng-container>

      <ng-container *ngIf="!isOpenEditModal && !isGradingForbidden && isVerbalAllowed">
        <div class="small-block">
          <nit-field-wrapper>
            <label class="checkbox-horizontal">
              <input type="checkbox"
                      [checked]="form.get('isVerbalFormed').value"
                      (change)="checkNushMark($event)">
              <span class="nush-mark">
                <img src="/assets/icons/green-tick.svg" alt="Green tick">
              </span>
            </label>
          </nit-field-wrapper>
        </div>
      </ng-container>

      <div *ngIf="isOpenEditModal && (!data.id || (data?.description && !(data.rating || data.customMark !== null ||
            data.isVerbalFormed !== null || data.leveledMark !== null || data.absense !== null)))"
            class="large-block simple-text adding-mark"
            (click)="nitEditAddStudentDataAfterDelayModal.open(data, false)">
        <span>Додати оцінку/<span class="presence">Н</span></span>
      </div>

      <div *ngIf="journalTableService.havePresence && !isOpenEditModal" class="small-block">
        <nit-field-wrapper>
          <label class="checkbox-horizontal">
            <input type="checkbox"
                   [checked]="absense === 0 || absense === 1"
                   (change)="checkCustomMark($event, 6)">
            <span class="presence">Н</span>
          </label>
        </nit-field-wrapper>
      </div>

      <div *ngIf="(!isOpenEditModal && !data?.description) || (isOpenEditModal && data.isFds)"
           class="large-block simple-text add-comment"
           (click)="nitAddEditStudentDataModal.open(data, 'add-comment')">
        <span>Додати коментар</span>
      </div>

      <div *ngIf="canDeleteGradingMark"
           class="large-block simple-text delete-mark"
           (click)="deleteMark()">
        <span>Видалити</span>
      </div>

      <ng-container *ngIf="data?.description">
        <div class="large-block simple-text add-comment"
             (click)="nitAddEditStudentDataModal.open(data, 'edit-comment')">
          <span>Редагувати коментар</span>
        </div>
        <div class="large-block simple-text delete-comment" (click)="nitDeleteCommentModal.open(data)">
          <span>Видалити коментар</span>
        </div>
      </ng-container>

      <ng-container *ngIf="isOpenEditModal">
        <ng-container *ngIf="canEditOrDeleteMark">
          <div *ngIf="!isGradingForbidden" class="large-block simple-text edit-mark"
               (click)="nitEditAddStudentDataAfterDelayModal.open(data, false)">
            <span>Редагувати</span>
          </div>
          <div class="large-block simple-text delete-comment"
               (click)="nitEditAddStudentDataAfterDelayModal.open(data, true)">
            <span>Видалити</span>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>

    <div *ngIf="data.isBlurred">
      <div *ngIf="canEditOrDeleteMark"
           class="large-block simple-text delete-mark"
           (click)="nitEditAddStudentDataAfterDelayModal.open(data, true)">
        <span>Видалити</span>
      </div>
      <div *ngIf="data?.description"
           class="large-block simple-text delete-comment"
           (click)="nitDeleteCommentModal.open(data)">
        <span>Видалити коментар</span>
      </div>
    </div>
  </div>
</div>

<nit-add-edit-student-data-modal #nitAddEditStudentDataModal
                                 (submitted)="addEditStudentData($event)">
</nit-add-edit-student-data-modal>
<nit-edit-add-student-data-after-delay-modal #nitEditAddStudentDataAfterDelayModal
                                             (submitted)="addEditStudentDataAfterDelay($event)">
</nit-edit-add-student-data-after-delay-modal>
<nit-delete-comment-modal #nitDeleteCommentModal
                          (submitted)="addEditStudentData($event)">
</nit-delete-comment-modal>
