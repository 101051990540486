import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'allowedColumn'
})
export class AllowedJournalColumnPipe implements PipeTransform {
  transform(currentType: number, allowedTypes: number[]): boolean {
    for (const testedType of allowedTypes) {
      if (testedType === currentType) {
        return true;
      }
    }

    return false;
  }
}
