import {Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild} from '@angular/core';
import {CompositeFilterDescriptor} from '@progress/kendo-data-query';
import {BaseFilterCellComponent} from '@progress/kendo-angular-grid';
import {ClassesForJournalKeeping} from '@nit-core/models/marks-changelog';
import {TeacherClasses} from '@nit-core/models/homework';
import {SelectEvent} from '@nit-core/models/fds';
import {Subject} from 'rxjs';

@Component({
  selector: 'nit-dropdown-filter',
  templateUrl: './dropdown-filter.component.html',
  styleUrls: ['./dropdown-filter.component.scss']
})
export class DropdownFilterComponent extends BaseFilterCellComponent implements OnInit {
  @ViewChild('dropdownSelect') dropdownSelect: ElementRef;

  @Input() height: string = '45';
  @Input() border: boolean = true;
  @Input() component: string;
  @Input() alias: string;
  @Input() filter: CompositeFilterDescriptor;
  @Input() data: Record<string, any>[];
  @Input() textField: string;
  @Input() valueField: string;
  @Input() defaultText: string;
  @Input() defaultValue: string = '';
  @Input() disabled: boolean = false;
  @Input() noDefaultItem: boolean = false;
  @Input() hasScroll: boolean = true;

  @Output() changeData: EventEmitter<string | number> = new EventEmitter<string | number>();
  @Output() opened: EventEmitter<SelectEvent> = new EventEmitter<SelectEvent>();
  @Output() clickOutside: EventEmitter<string> = new EventEmitter<string>();
  @Output() filterChange: EventEmitter<CompositeFilterDescriptor> = new EventEmitter<CompositeFilterDescriptor>();

  selectedOption$: Subject<Record<string, any>> = new Subject<Record<string, any>>();
  emptyText: string = '';
  selectedOption: Record<string, any> = null;
  defaultItem: Record<string, any>;
  isOpened: boolean;
  dateType: string;

  get selectedValue(): any {
    const filter = this.filterByField(this.valueField);
    if (!filter && this.defaultValue) {
      return this.defaultValue;
    }
    if (this.component === 'journalKeeping') {
      return filter ? (filter.value === null) ? 'Інше' : filter.value : '';
    } else if (this.component === 'requests-grid') {
      return filter && filter.value !== 4 ? filter.value : null;
    } else {
      return filter ? filter.value : null;
    }
  }

  @HostListener('document:click', ['$event.target']) onClick(target): void {
    if (this.isOpened) {
      const isOutside = !this.dropdownSelect.nativeElement.contains(target);

      if (isOutside) {
        this.isOpened = false;
        this.clickOutside.emit(this.component);
      }
    }
  }

  ngOnInit(): void {
    this.defaultItem = this.noDefaultItem && !this.defaultValue ? null : {
      [this.textField]: this.defaultText,
      [this.valueField]: this.defaultValue || ''
    };

    this.subscribeOptionChange();
  }

  toggleDropdown(): void {
    this.isOpened = !this.isOpened;
    this.opened.emit({
      isOpened: this.isOpened,
      dropdownSelect: this.dropdownSelect,
      selectedOption: this.selectedOption
    });
  }

  subscribeOptionChange(): void {
    this.selectedOption$.subscribe(option => {
      let value: string | number = option[this.valueField];
      this.selectedOption = option;

      switch (this.component) {
      case 'schoolInfo': {
        if (this.valueField === 'studentClassId' && !value) {
          this.removeFilter('isInClass');
          this.applyFilter(this.removeFilter(this.valueField));
        } else if (this.valueField === 'studentClassId' && value) {
          if (value === 'non') {
            this.removeFilter(this.valueField);
            this.applyFilter(
              this.updateFilter({
                field: 'isInClass',
                operator: 'eq',
                value: false
              })
            );
          } else {
            this.removeFilter('isInClass');
            this.applyFilter(
              this.updateFilter({
                field: 'studentClassId',
                operator: 'eq',
                value
              })
            );
          }
        } else if (this.valueField === 'aikomId') {
          this.applyFilter(
            value === '' ?
              this.removeFilter('aikomId') :
              this.updateFilter({
                field: 'aikomId',
                operator: value === 2 ? 'isnull' : 'isnotnull',
                value
              })
          );
        }
      } break;

      case 'school-list-aikom': {
        this.applyFilter(
          value === '' ?
            this.removeFilter('aikomId') :
            this.updateFilter({
              field: 'aikomId',
              operator: value === 2 ? 'isnull' : 'isnotnull',
              value
            })
        );
      } break;

      case 'remark': {
        this.applyFilter(
          value === '' ?
            this.removeFilter('teachers.id') :
            this.updateFilter({
              field: 'teachers.id',
              operator: 'eq',
              value
            })
        );
      } break;

      case 'journalKeeping':
      case 'homeworkTable.subject':
      case 'homeworkTable.class': {
        if (this.data[0] instanceof ClassesForJournalKeeping || this.data[0] instanceof TeacherClasses) {
          this.removeFilter('subject');
          this.removeFilter(this.component === 'journalKeeping' ? 'group' : 'groupId');
        }
        if (this.valueField === 'subject') {
          this.removeFilter(this.component === 'journalKeeping' ? 'group' : 'groupId');
        }
        this.applyFilter(
          value === '' ?
            this.removeFilter(this.valueField) :
            this.updateFilter({
              field: this.valueField,
              operator: value ? 'eq' : 'isnull',
              value
            })
        );

      } break;

      case 'journal-changes-month': {
        const root = this.filter || {
          logic: 'and',
          filters: []
        };
        const dateFilters = [];
        this.filter = this.removeFilter(this.valueField);

        if (value) {
          const selectedDate = new Date(value);
          const endsDate = new Date(new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, 0));

          dateFilters.push({
            field: this.valueField,
            operator: 'gte',
            value: this.dateType === 'finalDate' ? selectedDate.toDateString() : selectedDate
          });
          dateFilters.push({
            field: this.valueField,
            operator: 'lte',
            value: this.dateType === 'finalDate' ? endsDate.toDateString() : endsDate
          });

          if (dateFilters.length) {
            root.filters.push(...dateFilters);
          }
          this.filterService.filter(root);
        } else {
          this.applyFilter(this.removeFilter(this.valueField));
        }

        this.filterChange.emit(root);
      } break;

      case 'requests-grid': {
        this.applyFilter(
          this.updateFilter({
            field: this.valueField,
            operator: value === '' ? 'neq' : 'eq',
            value: value === '' ? 4 : value
          })
        );
      } break;

      case 'payment.type':
      case 'subjects.subjects.name':
      case 'payment.status': {
        this.applyFilter(
          value === '' || value === 'all' ?
            this.removeFilter(this.component) :
            this.updateFilter({
              field: this.component,
              operator: 'eq',
              value: value
            })
        );
        break;
      }

      case 'mark':
      case 'sender':
      case 'child': {
        if (value === 'Предмет' || value === 'Клас') {
          value = '';
        }
        this.applyFilter(
          this.updateFilter({
            field: this.component + '.' + this.valueField,
            operator: value === '' ? 'neq' : 'eq',
            value: value === '' ? this.valueField === 'subType' ? 6 : '' :
              this.valueField === 'subType' ? value as number + 1 : value
          })
        );
      } break;

      case 'socialGroups':
      case 'expiryTerm': {
        if (this.valueField === 'type') {
          this.applyFilter(
            value === '' ?
              this.removeFilter('socialGroups.' + this.valueField) :
              this.updateFilter({
                field: 'socialGroups.' + this.valueField,
                operator: 'eq',
                value
              })
          );
        } else if (this.valueField === 'periodType') {
          this.applyFilter(
            value === '' || value === 'all' ?
              this.removeFilter('socialGroups.to') :
              this.updateFilter({
                field: 'socialGroups.to',
                operator: value === 'active' ? 'gte' : 'lte',
                value: value === 'active' ?
                  new Date(new Date().setHours(0, 0, 0)).toISOString() :
                  new Date(new Date().setHours(23, 59, 59)).toISOString()
              })
          );
        }
      }
        break;

      case 'healthGroup': {
        this.applyFilter(
          value === '' || value === 'all' ?
            this.removeFilter('mainTo')
            :
            this.updateFilter({
              field: 'mainTo',
              operator: value === 'active' ? 'gte' : 'lte',
              value: new Date().toISOString()
            })
        );

      }
        break;

      default: {
        this.applyFilter(
          value === '' ?
            this.removeFilter(this.valueField) :
            this.updateFilter({
              field: this.valueField,
              operator: 'eq',
              value
            })
        );
      }
      }

      this.changeData.emit(value);
      this.isOpened = !this.isOpened;
    });
  }
}
