import {
  LeveledAssessmentType,
  ReplacementTypesValues
} from '@nit-core/global/domain/enums';
import {JournalDay} from '@nit-core/models/journal-day';
import {Teacher} from './class';

export class Journal {
  journalDay: JournalDay[];
  marks: Mark[];
  page: number;
  teacherId?: string;
  countPages: number;
  practiceJournalDays: any;
  activeDay: Date;
  currentDay: Date;
  allJournalDates: Date[];
  id?: string;
  teacherName?: string;
  subject: string;
  replacementTeachersIds: string[];
  className: string;
  userAverageMark: UserAverageMark[];
  userBlockedJournalDays: BlockedJournalDay[];
  schoolId: number;
  absenceReminders: AbsenceReminder[];
  classGroupId: string | null;
  classGroupName: string | null;
  classId: string;
  classNumber: string;
  classLetter: string;
  scheduleId: string;
  replacementType?: ReplacementTypesValues;
  displayName: string;
  isFds: boolean;
  teachers: Teacher[];

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.marks = item.marks ? item.marks.map((x: any) => new Mark(x)) : [];
    this.journalDay = item.journalDay ? item.journalDay.map(x => new JournalDay(x)) : [];
    this.teachers = item.teachers ? item.teachers.map(x => new Teacher(x)) : [];
    this.activeDay = item.activeDay ? new Date(item.activeDay) : null;
    this.currentDay = item.currentDay ? new Date(item.currentDay) : null;
    this.allJournalDates = item.allJournalDates ? item.allJournalDates.map((x: any) => new Date(x)) : [];
    this.userBlockedJournalDays = item.userBlockedJournalDays ?
      item.userBlockedJournalDays.map((x: any) => new BlockedJournalDay(x)) : [];
    this.userAverageMark = item.userAverageMark ? item.userAverageMark.map(x => new UserAverageMark(x)) : [];
    this.absenceReminders = item.absenceReminders ? item.absenceReminders.map(x => new AbsenceReminder(x)) : [];
    this.teachers = item.teachers ? item.teachers.map((x: any) => new Teacher(x)) : [];
    this.displayName = item.classGroupId
      ? item.classNumber + '-' + item.classLetter + ' гр.' + item.classGroupName
      : item.classNumber + '-' + item.classLetter;
  }
}

export class Mark {
  id: string;
  classId: string;
  groupId: string;
  subject: string;
  userId: string;
  rating: number;
  day: Date;
  markId?: string;
  customMark: number = null;
  absense: number = null;
  description: string;
  columnId: string;
  isBlockedForChanging: boolean = false;
  isShow: boolean;
  journalDayColumnName: string;
  journalDayShortColumnName: string;
  notes: string;
  point: string;
  leveledMark?: LeveledAssessmentType;
  isVerbalFormed?: boolean;
  scheduleId?: string;
  gradeMarkColor?: string;
  tipText?: string;
  journalDayId?: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.day = item?.day ? new Date(item.day) : null;
    this.isShow = false;
  }
}

export class ChangeMarkRequest extends Mark {
  newMarkPresence: number;
  newMarkRating: number;
  oldMarkPresence: number;
  oldMarkRating: number;
  oldCustomMark: number;
  newCustomMark: number;

  constructor(item: any = {}) {
    super();
    Object.assign(this, item);
  }
}

export class AverageMarkSettings {
  customColumns: string[];
  learningOutcomeColumns: string[];
  competenceIds: string[];
  from?: Date;
  to?: Date;
  excludeDayMarks: boolean;
  excludeThematicMarks: boolean;

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.from = item?.from ? new Date(item.from) : null;
    this.to = item?.to ? new Date(item.to) : null;
  }
}

export class UserAverageMark {
  averageMark: number;
  userId: string;
  columnId?: string;
  forColumnId?: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class ColumnForm {
  columnId?: string;
  text?: string;
  isThematic?: boolean;
  journalDay?: JournalDay;
  journalType?: number;
  isEdit?: boolean;
  semester?: number;
  journalIndex?: number;
  reason?: string;
  includeInDecision?: boolean;

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.journalDay = item?.journalDay ? new JournalDay(item.journalDay) : null;
  }
}

export class JournalEntity {
  id: string;
  classId: string;
  classGroupId: string;
  subject: string;
  scheduleId: string;
  teachers: Teacher[];

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.teachers = item.teachers ? item.teachers.map(x => new Teacher(x)) : [];
  }
}

export class BlockedJournalDay {
  blockedJournalDays: string[];
  userId: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class AverageScoreResponse {
  averageMarkSettings?: AverageMarkSettings;
  data: UserAverageMark[];
  date: string;
  total: number;

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.averageMarkSettings = item?.averageMarkSettings ? new AverageMarkSettings(item.averageMarkSettings) : null;
    this.data = item.data ? item.data.map(x => new UserAverageMark(x)) : [];
  }
}

export class CustomColumnsResponse {
  competenceColumns: JournalDay[];
  learningOutcomeColumns: JournalDay[];
  customColumns: JournalDay[];

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.competenceColumns = item.competenceColumns ? item.competenceColumns.map(day => new JournalDay(day)) : [];
    this.learningOutcomeColumns = item.learningOutcomeColumns ? item.learningOutcomeColumns.map(day => new JournalDay(day)) : [];
    this.customColumns = item.customColumns ? item.customColumns.map(day => new JournalDay(day)) : [];
  }
}

export class SubmittedData {
  journalDay: JournalDay;
  id?: string;
  updateAverage?: boolean;
  isAverageMarkSemester?: boolean;
  isThematic?: boolean;
  isCreate?: boolean;
  isFds?: boolean;

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.journalDay = item?.journalDay ? new JournalDay(item.journalDay) : null;
  }
}

export class AbsenceReminder {
  journalDayId: string;
  userId: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class ReplaceTeachersData {
  forTeacherId: string;
  teacherId: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}
