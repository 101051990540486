import {AfterContentInit, Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnDestroy, Output, QueryList, Renderer2, SimpleChanges, ViewChild, ViewChildren} from '@angular/core';
import {AcademicSemesterBounds, Journal, JournalDay, Mark, SchoolSettings} from '@nit-models';
import {GroupedArray} from '@nit-core/global/domain/grouped-array';
import {JournalColumnType, KendoFilterOperator} from '@nit-core/global/domain/enums';
import {
  ColumnCreationService,
  DynamicComponentStateService,
  JournalDayService,
  MarkService,
  NitToastr,
  RequestService,
  UserService
} from '@nit-services';
import {AuthService} from '@nit-auth';
import {JournalItemComponent} from './journal-item/journal-item.component';
import {DynamicComponentDirective} from '@nit-core/directives/dynamic-component.directive';
import {JournalItemMarkComponent} from './journal-item-mark/journal-item-mark.component';
import {Subscription} from 'rxjs';
import {NitForm} from '@nit-forms';
import {FormControl} from '@angular/forms';
import {
  AbsenceReminder,
  ChangeMarkRequest,
  ColumnForm,
  SubmittedData,
  UserAverageMark
} from '@nit-core/models/journal';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {BlockedDaysForStudents, JournalStudent} from '@nit-core/models/journal-student';
import {KendoDataQuery} from '@nit-core/models/common/kendo-data-query';
import {PermissionService} from '@nit-core/permission/permission.service';
import {BlurredInfo, JournalDayDeletedResponse} from '@nit-core/models/journal-day';
import {AddGroupOfResultsModalComponent} from '../modals/add-group-of-results-modal/add-group-of-results-modal.component';

@UntilDestroy()
@Component({
  selector: 'nit-journal-table',
  templateUrl: './journal-table.component.html',
  styleUrls: ['./journal-table.component.scss']
})
export class JournalTableComponent implements OnChanges, AfterContentInit, OnDestroy {
  @ViewChildren('journalItems') journalItems: QueryList<JournalItemComponent>;
  @ViewChild(DynamicComponentDirective, {static: true}) dynamicComponentDirective: DynamicComponentDirective;
  @ViewChild('addGroupOfResultsModal') addGroupOfResultsModal: AddGroupOfResultsModalComponent;
  @ViewChild('NitAddRating', {static: true}) addRating: ElementRef;
  @ViewChild('journalTable') journalTable: ElementRef;

  @Input() journal: Journal;
  @Input() students: JournalStudent[];
  @Input() currentSchedule: AcademicSemesterBounds;
  @Input() actualSettings: SchoolSettings;
  @Input() hasCompetences: boolean = false;
  @Input() isFifthOrGreater: boolean = false;
  @Input() isClassProfile: boolean = false;
  @Input() selectedTab: number;

  @Output() journalChanged: EventEmitter<void> = new EventEmitter<void>();
  @Output() journalDeleted: EventEmitter<void> = new EventEmitter<void>();
  @Output() lastColumnAdded: EventEmitter<void> = new EventEmitter<void>();

  groupedMarks: GroupedArray<Mark> = new GroupedArray<Mark>();
  groupedAverageMark: GroupedArray<UserAverageMark> = new GroupedArray<UserAverageMark>();
  groupedReminders: GroupedArray<AbsenceReminder> = new GroupedArray<AbsenceReminder>();
  journalType = JournalColumnType;
  rendererEmptyDays: number[] = [];
  headerPermissions: boolean[];
  cellPermissions: boolean[];
  userId: string;
  customJournalDays: CustomJournalDay[];
  isScrollAllowed = true;
  thematicAverageScore: UserAverageMark[] = [];
  averageMarks: UserAverageMark[] = [];
  isDisable: boolean;
  customName: string;
  lastThematicAdded: string[] = [];
  isAverageMarkSemester: boolean;
  hasDeleteDayPermission: boolean;
  isJournalTeacher: boolean = false;
  isClassTeacher: boolean = false;
  isMobile: boolean = false;
  isAdmin: boolean = false;
  blurredDaysForStudents: BlockedDaysForStudents;
  scrollSize: number | null = null;
  scrollMobileTable: boolean = false;

  form: NitForm = new NitForm({
    classId: new FormControl(),
    columnId: new FormControl(),
    customMark: new FormControl(),
    description: new FormControl(),
    groupId: new FormControl(),
    id: new FormControl(),
    absense: new FormControl(null),
    subject: new FormControl(),
    userId: new FormControl(),
    scheduleId: new FormControl(),
    rating: new FormControl(''),
    day: new FormControl(),
    isVerbalFormed: new FormControl(),
    leveledMark: new FormControl()
  });

  private _selectedCell: number | null = null;
  private readonly _subscription = new Subscription();

  constructor(private readonly _marksService: MarkService,
              private readonly _journalDayService: JournalDayService,
              private readonly _authService: AuthService,
              private readonly _columnCreationService: ColumnCreationService,
              private readonly _toast: NitToastr,
              private readonly _renderer: Renderer2,
              private readonly _dynamicComponentService: DynamicComponentStateService,
              private readonly _requestService: RequestService,
              private readonly _permissionsService: PermissionService,
              private readonly _userService: UserService) {
    this.isMobile = window.innerWidth < 768;
    this.isAdmin = this._permissionsService.hasPermission(['read:school-user']);
    this.userId = this._authService.userId;
  }

  @HostListener('window:scroll', ['$event'])
  onWindowsScroll() {
    if (this.isMobile) {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollTop >= 439) {
        this.scrollMobileTable = true;
      } else {
        this.scrollMobileTable = false;
      }
    }
  }

  @HostListener('touchstart', ['$event.target']) onTouch(target) {
    const isJournalTable = this.journalTable.nativeElement.contains(target);
    if (isJournalTable && this.isMobile) {
      this.scrollMobileTable = true;
    }
  }

  ngAfterContentInit(): void {
    this.transformDate();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.journal?.currentValue) {
      this._checkDeleteDayPermission();

      if (this.journal.classId) {
        const classes = this._userService.currentSchoolInfo$.value.classes;
        this.isClassTeacher = classes.some(currentClass => currentClass.id === this.journal.classId);
        this.isJournalTeacher = this.journal.teachers.some(x => x.id === this.userId);
      }
    }

    if (this.journal && this.students) {
      this.transformDate();
      this.checkDate(this.journal.journalDay);
      this._setBlurredDays();
    }

    if (this.journal?.marks) {
      this.groupedMarks = new GroupedArray<Mark>(this.journal.marks, ['columnId', 'userId']);
      const length = this.customJournalDays.length;
      this.rendererEmptyDays = length < 17 ? Array.from(Array(17 - length).keys()) : [];
      this.getPermissions(this.customJournalDays);
    }

    if (!this.journal.currentDay && !!this.students && !this.journal?.marks?.length) {
      this.rendererEmptyDays = Array.from(Array(17).keys());
    }

    if (this.journal?.absenceReminders) {
      this.groupedReminders = new GroupedArray<AbsenceReminder>(this.journal?.absenceReminders, ['journalDayId', 'userId']);
    }
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  transformDate(): void {
    const hasActiveDay: number = this.journal?.journalDay?.findIndex(journalDay => journalDay?.date.getTime() === this.journal.currentDay.setHours(0, 0, 0, 0));
    if (this.journal && this.journal?.journalDay?.length > 0 && hasActiveDay !== -1) {
      this._scrollToTable();
    }
  }

  checkDate(journalDays: JournalDay[]): void {
    const today = new Date(new Date(this.journal.currentDay).setHours(0, 0, 0, 0));
    this.customJournalDays = journalDays.map(d => {
      d.date = new Date(d.date.setHours(0, 0, 0, 0));

      return new CustomJournalDay(d);
    });

    this.customJournalDays.forEach(day => {
      const fixHoursDate = new Date(new Date(day.date).setHours(0, 0, 0, 0));
      day.didNotPassDateValidation = fixHoursDate > today && (day.type === JournalColumnType.Day || day.type === JournalColumnType.Thematic || day.type === JournalColumnType.Custom);
      day.canTransfer = this._canTransfer(day.id);
      day.isEmpty = this._isEmpty(day);
      day.canBeDeleted = this._canBeDeleted(day);
    });
  }

  closeDialog(): void {
    if (this.isMobile) {
      const journalTableEl = this.journalTable.nativeElement;

      if (this.scrollSize !== null) {
        this.scrollSize = journalTableEl.scrollTop;
        if (this.scrollSize < 1) {
          this.scrollMobileTable = false;
        }
      } else if (journalTableEl.scrollTop > 5) {
        this.scrollSize = journalTableEl.scrollTop;
      }
    }

    if (this.isScrollAllowed) {
      if (this._selectedCell !== null) {
        this.journalItems.toArray()[this._selectedCell].isRatingEditing = false;
      }
      if (this._dynamicComponentService.dynamicComponentStateSubject.getValue()) {
        this._dynamicComponentService.updateDynamicComponentState(null);
      }
    }
  }

  trackBy(index: number): number {
    return index;
  }

  currentAddBlock(xIndex: number, yIndex: number, isOutside?: boolean): void {
    this.isScrollAllowed = false;
    setTimeout(() => this.isScrollAllowed = true, 0);
    this._initDynamicComponent();

    if (isOutside) {
      this._selectedCell = (this.customJournalDays.length * xIndex) + yIndex;
      this.journalItems.toArray()[this._selectedCell].isRatingEditing = false;
    } else {
      if (this._selectedCell !== null) {
        this.journalItems.toArray()[this._selectedCell].isRatingEditing = false;
      }
      this._selectedCell = (this.customJournalDays.length * xIndex) + yIndex;
    }
  }

  addDeleteYearColumn(data: JournalDay): void {
    const journalDay = {
      name: 'Річна',
      fullName: 'Річна',
      columnId: data.id,
      columnType: JournalColumnType.Year,
      index: +data.journalIndex,
      date: data.date,
      averageColumnId: data.averageColumnId,
    };

    this._journalDayService.create(journalDay).subscribe(() => {
      this.journalChanged.emit();
    });
  }

  addUpdateDeleteColumn(data: SubmittedData, type: boolean): void {
    this.isAverageMarkSemester = data.isAverageMarkSemester;

    if (data.isCreate) {
      this._journalDayService.create(data.journalDay).subscribe(res => {
        if (res) {
          if (data.journalDay?.columnType === JournalColumnType.CustomSemester || data.journalDay?.columnType === JournalColumnType.CustomYear) {
            this.journalChanged.emit();
          } else {
            const lastElements = this.customJournalDays.slice(data.isThematic && !data.isFds ? -2 : -1);
            this._removeColumnsFromBlockedJournalDays(lastElements);
            this.createJournalColumn(res.id, data, data.isThematic && !data.isFds ? 2 : 1);
          }
        }
      });
    } else if (data.updateAverage) {
      this._journalDayService.update(data.id, data.journalDay).subscribe(() => {
        const journalDay = this.customJournalDays.find(x => x.id === data.id);
        if (journalDay) {

          journalDay.averageMarkSettings = data.journalDay.averageMarkSettings;
          this.journal.journalDay.find(x => x.id === data.id).averageMarkSettings =
            journalDay.averageMarkSettings;

          this.getAverageScore(journalDay.id, journalDay.averageColumnId);
        } else {
          this.getAverageScore(data.journalDay.averageColumnId, data.journalDay.columnId);
        }
        this._toast.success('Дані завантажено та підлягають редагуванню за необхідності');
      });
    } else {
      if (type) {
        this._journalDayService.update(data.id, data.journalDay).subscribe(() => {
          if (data.journalDay?.columnType === JournalColumnType.CustomSemester || data.journalDay?.columnType === JournalColumnType.CustomYear) {
            this.journalChanged.emit();
          } else {
            const journalDay = this.customJournalDays.find(x => x.id === data.id);
            journalDay.name = data.journalDay.name;
            journalDay.fullName = data.journalDay.fullName;
            this._toast.success('Стовпець було успішно відредаговано');
            this.customName = data.journalDay.name;
          }
        });
      } else {
        this._journalDayService.delete(data.id).subscribe((res: JournalDayDeletedResponse) => {
          this._toast.success('Стовпець було успішно видалено');
          if (res.isJournalDeleted) {
            this.journalDeleted.emit();
          } else {
            this.journalChanged.emit();
          }
        });
      }
    }
  }

  createJournalColumn(id: string, data: SubmittedData, quantity: number): void {
    const index = this.customJournalDays.findIndex(x => x.id === data.journalDay.columnId);
    const averageColumn = this.createObject(id, data.journalDay);
    const thematicOrCustomColumn = this.createObject(id, data.journalDay, data.isThematic && !data.isFds ? '0' : '1');

    if ((index >= 15 && data.isThematic && !data.isFds)
      || (index === 16 && !data.isThematic)
      || (index === 16 && data.isThematic && data.isFds)) {
      this.lastColumnAdded.emit();
    } else {
      this.customJournalDays.forEach(item => {
        if (item.index > data.journalDay.index) {
          item.index += quantity;
        }
      });

      if (!this.rendererEmptyDays.length) {
        this.customJournalDays.splice(-quantity, quantity);
      }
      this.customJournalDays.push(thematicOrCustomColumn);

      if (data.isThematic) {
        if (this.rendererEmptyDays.length > 1) {
          this.rendererEmptyDays.splice(-2, 2);
        } else if (this.rendererEmptyDays.length === 1) {
          this.rendererEmptyDays.splice(-1, 1);
        }

        if (!data.isFds) {
          this.customJournalDays.push(averageColumn);
          this.lastThematicAdded.push(averageColumn.averageColumnId);
          this._updateUserBlockedJournalDays(data.journalDay.columnId, [averageColumn.id]);
          this.getAverageScore(id, averageColumn.id);
        }
        this._updateUserBlockedJournalDays(data.journalDay.columnId, [thematicOrCustomColumn.id]);
        this._toast.success(data.isFds ? 'Стовпець успішно створено' : 'Дані завантажено та підлягають редагуванню за необхідності');
      } else {
        if (this.rendererEmptyDays.length >= 1) {
          this.rendererEmptyDays.splice(-1, 1);
        }

        this._updateUserBlockedJournalDays(thematicOrCustomColumn.id, [data.journalDay.columnId]);
      }
    }
    this.customJournalDays.naturalSort('index');
    this.getPermissions(this.customJournalDays);
  }

  createObject(id: string, data: JournalDay, index?: string): CustomJournalDay {
    const date = this.customJournalDays.find(day => day.index === data.index).date;
    const journalDay = new CustomJournalDay({
      id: index === '0' || index === '1' ? id : data?.index.toString(),
      date: data.columnType === JournalColumnType.Custom ? date : data?.date,
      index: data?.index + (index === '0' ? 2 : 1),
      type: index === '0' ? 1 : index === '1' ? 4 : 11,
      isDelete: false,
      isCustomLesson: false,
      teacherId: data?.teacherId,
      journalId: data?.journalId,
      blurredForStudents: {},
      lesson: data?.lesson,
      name: index === '0' ? 'Темат.' : index === '1' ? data?.name : null,
      averageColumnId: index === '0' ? data?.index.toString() : index === '1' ? null : id,
      fullName: index === '0' ? 'Тематична' : index === '1' ? data?.fullName : null,
      averageMarkSettings: index === '0' ? data?.averageMarkSettings : null,
    });
    journalDay.canTransfer = this._canTransfer(journalDay.id);
    journalDay.isEmpty = this._isEmpty(journalDay);
    journalDay.canBeDeleted = this._canBeDeleted(journalDay);

    return journalDay;
  }

  getPermissions(journalDays: CustomJournalDay[]): void {
    this.headerPermissions = [];
    this.cellPermissions = [];
    journalDays.forEach((journalDay, index) => {
      const permission = journalDay.teacherId === this.userId ||
        (this.isJournalTeacher && (journalDay.teacherId === null || this._isColumnHasDefaultType(journalDay)));
      this.cellPermissions[index] = permission;

      this.headerPermissions[index] = permission || (journalDay.type === this.journalType.Day && this.hasDeleteDayPermission)
        || ((journalDay.type === this.journalType.Custom || journalDay.type === this.journalType.LearningOutcome
        || journalDay.type === this.journalType.Thematic) && (this.isJournalTeacher || this.isAdmin))
        || ((journalDay.type === this.journalType.Semester || journalDay.type === this.journalType.Year ||
          journalDay.type === this.journalType.CustomSemester || journalDay.type === this.journalType.CustomYear) &&
          (journalDay.teacherId === this.userId || this.isJournalTeacher));
    });
  }

  createRating(event: { mark: Mark, type: string }): void {
    this.dynamicComponentDirective.viewContainerRef.clear();
    this.form.reset();
    this.form.get('scheduleId').patchValue(this.journal.scheduleId);
    this.form.patchValue(event.mark);

    if (event.mark && event.type !== 'request') {
      if (event.mark?.id) {
        if (event.mark.absense === null && (event.mark.description === null || event.mark.description === '') &&
          event.mark.customMark === null && !event.mark.rating &&
          event.mark.isVerbalFormed === null && event.mark.leveledMark === null) {
          this._marksService.delete(event.mark.id).subscribe(() => {
            const index = this.journal.marks.findIndex(x => x.id === event.mark.id);
            this.journal.marks.splice(index, 1);
            this.groupedMarks = new GroupedArray<Mark>(this.journal.marks, ['columnId', 'userId']);
            this._revalidateIsEmpty(event.mark.columnId);
            this._toast.success('Оцінку було видалено');
            this.closeDialog();
          });
        } else {
          this._marksService.update(event.mark?.id, this.form.value).subscribe(res => {
            const index = this.journal.marks.findIndex(x => x.id === event.mark.id);
            this.journal.marks[index] = res;
            this.groupedMarks = new GroupedArray<Mark>(this.journal.marks, ['columnId', 'userId']);
            this._toast.success('Зміни збережено');
            this.closeDialog();
          });
        }
      } else {
        this._marksService.create(this.form.value).subscribe(res => {
          this.journal.marks.push(res as Mark);
          this.groupedMarks = new GroupedArray<Mark>(this.journal.marks, ['columnId', 'userId']);
          this._revalidateIsEmpty(res.journalDayId);
          this._toast.success('Дані збережено');
          this.closeDialog();
        });
      }
    } else {
      event.mark.isBlockedForChanging = true;
      const index = this.journal.marks.findIndex(x => x.id === event.mark.id);
      if (index === -1) {
        this.journal.marks.push(event.mark as Mark);
      } else {
        this.journal.marks[index] = event.mark;
      }
      this._toast.success('Заявку відправлено');
      this.groupedMarks = new GroupedArray<Mark>(this.journal.marks, ['columnId', 'userId']);
      this._initDynamicComponent();
    }
  }

  moveColumn(data: {id: string, isRight: boolean}): void {
    this._journalDayService.moveJournalDay(data.id, data.isRight).subscribe(() => {
      this.journalChanged.emit();
      this._toast.success('Колонку успішно посунуто');
    });
  }

  getAverageScore(averageColumnId: string, columnId?: string, journalDayType?: JournalColumnType, eventType?: string): void {
    if (!this.lastThematicAdded.includes(averageColumnId) && eventType) {
      this.lastThematicAdded.push(averageColumnId);
    }

    if (this.lastThematicAdded.includes(averageColumnId) || journalDayType === JournalColumnType.AverageMarkYear ||
      journalDayType === JournalColumnType.AverageMarkLearningOutcome || journalDayType === JournalColumnType.AverageMarkSemester) {
      this.isDisable = true;
      this._journalDayService.getAverageScore(averageColumnId).subscribe(res => {
        this.thematicAverageScore = this.thematicAverageScore.filter(item => item.forColumnId !== averageColumnId);

        res.data.map(averageMark => {
          averageMark.forColumnId = averageMark.forColumnId ? averageMark.forColumnId : averageColumnId;
          this.thematicAverageScore.push(averageMark);

          return averageMark.columnId = columnId;
        });

        this.averageMarks = this.averageMarks.filter(item => item.forColumnId !== averageColumnId);
        this.averageMarks.push(...res.data);

        this.groupedAverageMark = new GroupedArray(this.averageMarks, ['forColumnId', 'userId']);

        const journalDay = this.customJournalDays.find(e => e.id === columnId);
        if (journalDay) {
          journalDay.canTransfer = this._canTransfer(journalDay?.id);
          
        }

        this.isDisable = false;
      });
    }
  }

  transferringJournalColumn(data: ColumnForm): void {
    const marks: Mark[] = [];
    this.thematicAverageScore.filter(item => item.columnId === data.journalDay.id).forEach(item => {
      if (item.averageMark !== 0) {
        marks.push({
          id: null,
          absense: null,
          customMark: null,
          day: data.journalDay.date,
          isBlockedForChanging: false,
          isShow: null,
          journalDayColumnName: '',
          journalDayShortColumnName: '',
          notes: '',
          point: '',
          rating: Number.isInteger(item.averageMark) ? item.averageMark : Math.round(item.averageMark),
          classId: this.journal.classId,
          groupId: this.journal.classGroupId,
          subject: this.journal.subject,
          userId: item?.userId,
          columnId: data?.columnId,
          scheduleId: this.journal.scheduleId,
          description: null,
          leveledMark: null,
          isVerbalFormed: null,
        });
      }
    });
    this._marksService.addAverageRating(marks).subscribe(res => {
      if (res.data) {
        res.data.forEach((el) => {
          const mark = this.journal.marks.find(x => x.id === el.id);
          if (mark) {
            mark.rating = el.rating;
          } else {
            this.journal.marks.push(el);
          }
          this.groupedMarks = new GroupedArray<Mark>(this.journal.marks, ['columnId', 'userId']);
        });
      }
    });
  }

  openAddGroupOfResultsModal(event: {columnId: string, date: Date}): void {
    this.addGroupOfResultsModal.open(event.columnId, event.date);
  }

  sendBatchGradesAndReason(value: ColumnForm): void {
    const data = {models: []};
    const queryFilters: KendoDataQuery = new KendoDataQuery(0, 99);
    queryFilters.pushFilters([{field: 'journalDayId', operator: KendoFilterOperator.Eq, value: value.journalDay.averageColumnId}]);

    this._marksService.all(queryFilters).subscribe(result => {
      if (result) {
        this.averageMarks.forEach(item => {
          if ((item.columnId === value.journalDay.id) && item.averageMark !== 0) {
            const oldMark: ChangeMarkRequest = <ChangeMarkRequest>result.data.find(i => i.userId === item.userId);
            data.models.push({
              markId: oldMark?.id || null,
              classId: this.journal.classId,
              class: this.journal.className,
              groupId: this.journal.classGroupId,
              childId: item?.userId,
              subject: this.journal.subject,
              markDay: value?.journalDay.date,
              newMarkRating: Number.isInteger(item?.averageMark) ? item?.averageMark : Math.round(item?.averageMark),
              newMarkPresence: null,
              newCustomMark: null,
              reasonOfChanging: value?.reason,
              type: '4',
              columnId: value?.columnId,
              scheduleId: this.journal.scheduleId,
              oldCustomMark: oldMark?.oldCustomMark || null,
              oldMarkRating: oldMark?.rating || null,
              oldMarkPresence: oldMark?.oldMarkPresence || null,
              description: null,
              isDeleting: false,
              isCustomJournal: false,
              isBlockedForChanging: true
            });
          }
        });

        this._requestService.sendBatchGradesAndReason(data).subscribe(res => {
          if (res.results) {
            this.journal.marks
              .filter(el => el.columnId === value.journalDay.averageColumnId)
              .forEach(el => el.isBlockedForChanging = true);

            data.models.forEach((item, index) => {
              if (item.markId !== null) return;
              this.journal.marks.push({
                groupId: item.groupId,
                journalDayColumnName: '',
                journalDayShortColumnName: '',
                notes: '',
                point: '',
                absense: null,
                classId: item.classId,
                columnId: item.columnId,
                customMark: null,
                day: item.day,
                description: null,
                id: res.results[index],
                isBlockedForChanging: true,
                isShow: false,
                leveledMark: null,
                rating: null,
                scheduleId: item.scheduleId,
                subject: item.subject,
                userId: item.childId,
                isVerbalFormed: null,
              });
            });
            this.groupedMarks = new GroupedArray<Mark>(this.journal.marks, ['columnId', 'userId']);
          }
          this._toast.success('Заявку відправлено');
        });
      }
    });
  }

  private _revalidateIsEmpty(journalDayId: string): void {
    const journalDay = this.customJournalDays.find(x => x.id === journalDayId);
    if (journalDay) {
      journalDay.isEmpty = this._isEmpty(journalDay);
    }
  }

  private _isEmpty(journalDay: JournalDay): boolean {
    return !this.journal.marks.some(mark => mark?.columnId === journalDay?.id);
  }

  private _canBeDeleted(journalDay: JournalDay): boolean {
    switch (journalDay.type) {
    case JournalColumnType.Day:
      return this.isAdmin || this.isClassTeacher;
    case JournalColumnType.Custom:
    case JournalColumnType.Thematic:
    case JournalColumnType.Year:
    case JournalColumnType.CustomSemester:
    case JournalColumnType.CustomYear:
    case JournalColumnType.CustomLearningOutcome:
      return journalDay.teacherId === this.userId || this.isJournalTeacher;
    default:
      return false;
    }
  }

  private _canTransfer(id: string): boolean {
    return this.averageMarks?.some(el => el.columnId === id && el.averageMark !== 0);
  }

  private _updateUserBlockedJournalDays(columnId: string, columnIds: string[]): void {
    this.journal.userBlockedJournalDays.forEach(item => {
      if (item.blockedJournalDays.includes(columnId)) {
        item.blockedJournalDays.push(...columnIds);
      }
    });
    this._setBlurredDays();
  }

  private _removeColumnsFromBlockedJournalDays(days: CustomJournalDay[]): void {
    this.journal.userBlockedJournalDays.forEach((item) => {
      if (item.blockedJournalDays.length) {
        item.blockedJournalDays = item.blockedJournalDays.filter(id => !days.map(x => x.id).includes(id));
      }
    });
  }

  private _isColumnHasDefaultType(journalDay: JournalDay): boolean {
    return journalDay.type === JournalColumnType.Semester || journalDay.type === JournalColumnType.SemesterChanged
      || journalDay.type === JournalColumnType.Year || journalDay.type === JournalColumnType.YearChanged
      || journalDay.type === JournalColumnType.DPA || journalDay.type === JournalColumnType.CustomLearningOutcome
      || journalDay.type === JournalColumnType.AverageMarkSemester || journalDay.type === JournalColumnType.AverageMarkYear;
  }

  private _setBlurredDays(): void {
    this.blurredDaysForStudents = {};
    this.customJournalDays.forEach(journalDay => {
      this.students.forEach(student => {
        const userBlockedJournalDay = this.journal.userBlockedJournalDays.find(day =>
          day.userId === student.userId);
        if (userBlockedJournalDay?.blockedJournalDays.includes(journalDay.id)) {
          const studentBlockedDays = this.blurredDaysForStudents[student.userId] || [];

          if (!studentBlockedDays.includes(journalDay.id)) {
            studentBlockedDays.push(journalDay.id);
          }
          this.blurredDaysForStudents[student.userId] = studentBlockedDays;
        }
      });
    });
    this._prepareBlurredDays(this.customJournalDays, this.students, this.blurredDaysForStudents);
  }

  private _scrollToTable(): void {
    setTimeout(() => {
      const el = this._renderer.selectRootElement('.journalCurrentDate');
      const pos = el.getBoundingClientRect();
      const table = document.querySelector('#journal-table');
      window.scrollTo({top: pos.top, behavior: 'smooth'});
      table.scroll({left: pos.left - 180, behavior: 'smooth'});
    }, 100);
  }

  private _initDynamicComponent(): void {
    const dynamicComponentStateStream$ = this._dynamicComponentService.dynamicComponentState$.subscribe((component) => {
      this._buildComponent(component);
    });
    if (dynamicComponentStateStream$) {
      this._subscription.add(dynamicComponentStateStream$);
    }
  }

  private _buildComponent(component: any): void {
    this.dynamicComponentDirective.viewContainerRef.clear();
    if (component) {
      const componentRef = this.dynamicComponentDirective.viewContainerRef.createComponent(JournalItemMarkComponent);
      (componentRef.instance as any).addRating = this.addRating;
      (componentRef.instance as any).params = component;
      componentRef.instance.journalChanged.pipe(untilDestroyed(this)).subscribe(resForm => this.createRating(resForm));
      componentRef.instance.visible.pipe(untilDestroyed(this)).subscribe(val => {
        this.dynamicComponentDirective.viewContainerRef.clear();

        this.closeDialog();
        if (val) {
          this.currentAddBlock(val?.coordinates[0], val?.coordinates[1], val?.isOutside);
        }
      });
    }
  }

  private _checkDeleteDayPermission(): void {
    const classTeacherIds = this._userService.userPermissions$.value.permissions.find(permission => {
      return permission.schoolId === +localStorage.getItem('schoolId');
    })?.classIds;

    this.hasDeleteDayPermission = this._permissionsService.hasPermission(['delete:journal-day:any']) ||
      (this._permissionsService.hasPermission(['delete:journal-day']) &&
        classTeacherIds.includes(this.journal.classId));
  }


  private _prepareBlurredDays(journalDays: CustomJournalDay[], students: JournalStudent[], blurredDaysForStudents: BlockedDaysForStudents): void {
    journalDays.forEach(journalDay => {
      const blurredForStudents = {};
      students.forEach(student => {
        const isBlurredCell = blurredDaysForStudents[student.userId]?.includes(journalDay.id);
        if (isBlurredCell) {
          blurredForStudents[student.userId] = isBlurredCell;
        }
      });

      journalDay.blurredForStudents = blurredForStudents;
    });
  }
}

export class CustomJournalDay extends JournalDay {
  didNotPassDateValidation?: boolean;
  canBeDeleted: boolean;
  isEmpty: boolean;
  canTransfer: boolean;
  blurredForStudents?: BlurredInfo;

  constructor(item: any = {}) {
    super(item);
    this.blurredForStudents = item.blurredForStudents ? new BlurredInfo(item.key) : null;
  }
}
