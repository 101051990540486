import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import {NitForm} from '@nit-forms';
import {FormControl} from '@angular/forms';
import {DynamicComponentStateService, RequestService} from '@nit-services';
import {Mark} from '@nit-models';
import {DynamicComponentDate, DynamicComponentRes} from '@nit-core/models/journal-item';
import {DynamicComponentDirective} from '@nit-core/directives/dynamic-component.directive';
import {AbsenceType, LeveledAssessmentType} from '@nit-core/global/domain/enums';
import {GenericEvent} from '@nit-core/models/generic-event';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {JournalTableService} from '../journal-table.service';
import {Grading} from '@nit-core/models/nush-rule';
import {ChangeMarkRequest} from '@nit-core/models/journal';

@UntilDestroy()
@Component({
  selector: 'nit-journal-item-mark',
  templateUrl: './journal-item-mark.component.html',
  styleUrls: ['./journal-item-mark.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class JournalItemMarkComponent implements OnInit, AfterViewInit {
  @ViewChild(DynamicComponentDirective, {static: true}) adHost!: DynamicComponentDirective;
  @ViewChild('addMark', {static: true}) addMark: ElementRef;
  @Input() data: DynamicComponentDate;
  @Input() isEmpty: boolean;
  @Output() journalChanged: EventEmitter<{mark: Mark, type: string}> = new EventEmitter<{mark: Mark, type: string}>();
  @Output() visible: EventEmitter<{coordinates: number[], isOutside: boolean}> =
    new EventEmitter<{coordinates: number[], isOutside: boolean}>();

  isOpenEditModal: boolean;
  isRightEnd: boolean = false;
  isGradingForbidden: boolean = false;
  isVerbalAllowed: boolean = false;
  isLeveledAllowed: boolean = false;
  isDigitalAllowed: boolean = false;
  customReleased: boolean = false;
  customNotCertified: boolean = false;
  customAccepted: boolean = false;
  customNotAccepted: boolean = false;
  customLearned: boolean = false;
  absense: number = null;
  customNotRated: boolean = false;
  absent: AbsenceType = AbsenceType.Absent;
  journalGrading: Grading;
  offsetHeight: number;
  res: DynamicComponentRes;

  form: NitForm = new NitForm({
    id: new FormControl(),
    columnId: new FormControl(),
    groupId: new FormControl(null),
    scheduleId: new FormControl(),
    userId: new FormControl(),
    classId: new FormControl(),
    class: new FormControl(),
    customMark: new FormControl(),
    description: new FormControl(),
    absense: new FormControl(null),
    subject: new FormControl(),
    rating: new FormControl(''),
    leveledMark: new FormControl(),
    isVerbalFormed: new FormControl(),
    day: new FormControl(),
    isFds: new FormControl()
  });

  private _customMark: number;
  private _coordinates: number[];

  get canDeleteGradingMark(): boolean {
    return this.isGradingForbidden && (this.data.rating != null || this.data.customMark !== null ||
      this.data.isVerbalFormed !== null || this.data.leveledMark !== null);
  }
  get canEditOrDeleteMark(): boolean {
    return this.data.rating != null || this.data.customMark !== null ||
      this.data.absense !== null || this.data.leveledMark !== null || this.data.isVerbalFormed;
  }

  constructor(public journalTableService: JournalTableService,
              private readonly _elementRef: ElementRef,
              private readonly _renderer: Renderer2,
              private readonly _requestService: RequestService,
              private readonly _dynamicComponentService: DynamicComponentStateService) {
    this._resetFieldsForCustomMark();
    this.journalTableService.resetAvailableCustomMark();
    this.journalTableService.checkboxCheckingToOpen(this.data);
  }

  ngOnInit(): void {
    this._dynamicComponentService.dynamicComponentState$.pipe(untilDestroyed(this)).subscribe(res => {
      if (res) {
        this.res = res;
        this._coordinates = res.data?.coordinates;
        this.isGradingForbidden = res.data.journalGrading.isGradingForbidden;
        this.isVerbalAllowed = res.data.journalGrading.isVerbalGradingActive;
        this.isLeveledAllowed = res.data.journalGrading.isLeveledGradingActive;
        this.isDigitalAllowed = res.data.journalGrading.isDigitalGradingActive;

        this.form.patchValue(res.data);
        this.data = res.data;
        this._resetFieldsForCustomMark();
        this.journalTableService.resetAvailableCustomMark();
        this.journalTableService.checkboxCheckingToOpen(this.data);
        this._saveCheckbox();
        this.isEmpty = res.isEmpty;
        this.isOpenEditModal = res.isOpenEditModal;

        this.isRightEnd = window.innerWidth < res.cord?.x + 160;
      }
    });
  }

  checkCustomMark(event: GenericEvent<HTMLInputElement>, type: number): void {
    event.preventDefault();
    this._resetFieldsForCustomMark();

    switch (type) {
    case 0: {
      this.customReleased = event.target.checked;
      this._setCustomMark(this.customReleased, type);
      break;
    }
    case 1: {
      this.customNotCertified = event.target.checked;
      this._setCustomMark(this.customNotCertified, type);
      break;
    }
    case 2: {
      this.customAccepted = event.target.checked;
      this._setCustomMark(this.customAccepted, type);
      break;
    }
    case 3: {
      this.customLearned = event.target.checked;
      this._setCustomMark(this.customLearned, type);
      break;
    }
    case 4: {
      this.customNotRated = event.target.checked;
      this._setCustomMark(this.customNotRated, type);
      break;
    }
    case 5: {
      this.customNotAccepted = event.target.checked;
      this._setCustomMark(this.customNotAccepted, type);
      break;
    }
    case 6: {
      this.absense = event.target.checked ? this.absent : null;
      this._absentCheck(this.absense);
    }
    }
  }

  checkNushMark(event: GenericEvent<HTMLInputElement>,
    type?: LeveledAssessmentType | boolean,
    isLeveled?: boolean): void {
    event.stopPropagation();
    this._resetFieldsForCustomMark();

    if (event.target.checked) {
      if (isLeveled) {
        this.form.get('leveledMark').patchValue(type);
        this.form.get('isVerbalFormed').patchValue(null);
      } else {
        this.form.get('leveledMark').patchValue(null);
        this.form.get('isVerbalFormed').patchValue(true);
      }

      this.form.get('absense').patchValue(null);
      this.form.get('rating').patchValue(null);
      this.form.get('customMark').patchValue(null);
      this._onChangeMark();
    } else {
      this.deleteMark();
    }
  }

  addEditStudentDataAfterDelay(markRequest: {formData: FormData, formValue: ChangeMarkRequest}): void {
    this._requestService.create(markRequest.formData).subscribe(response => {
      if (!this.form.get('id').value) {
        this.form.get('id').patchValue(response.id);
      } else {
        this.form.get('id').patchValue(markRequest.formValue.markId);
      }
      if (markRequest.formValue.oldMarkPresence !== null) {
        this.form.get('absense').patchValue(markRequest.formValue.oldMarkPresence);
      }
      if (markRequest.formValue.newMarkRating && markRequest.formValue.oldMarkPresence === null) {
        this.form.get('absense').patchValue(null);
      }
      this.journalChanged.emit({mark: this.form.value, type: 'request'});
      this.visible.emit({coordinates: this._coordinates, isOutside: true});
    });
  }

  addEditStudentData(mark: Mark): void {
    mark.description = mark.description === '' ? null : mark.description;
    this.form.patchValue(mark);
    this.form.get('scheduleId').patchValue(this.data.scheduleId);
    this._onChangeMark();
  }

  deleteMark(): void {
    const mark = {
      ...this.form.value,
      id: this.form.value.id,
      absense: null,
      customMark: null,
      description: this.form.value.description,
      isVerbalFormed: null,
      leveledMark: null,
      rating: null
    };
    this.journalChanged.emit({mark, type: ''});
    this.visible.emit(null);
  }

  @HostListener('document:click', ['$event']) onDocumentClick(): void {
    this.visible.emit({coordinates: this._coordinates, isOutside: true});
  }

  ngAfterViewInit(): void {
    this.offsetHeight = this.addMark.nativeElement.offsetHeight;
    if (window.innerWidth < this.res.cord?.x + 225) {
      this._renderer.setStyle(this._elementRef.nativeElement.childNodes[0], 'left', (this.res.cord?.x + -250) + 'px');
    } else {
      this._renderer.setStyle(this._elementRef.nativeElement.childNodes[0], 'left', (this.res.cord?.x + 32) + 'px');
    }
    if (window.innerHeight < this.res.cord?.y + 200) {
      this._renderer.setStyle(this._elementRef.nativeElement.childNodes[0], 'top', (this.res.cord?.y - this.offsetHeight) + 'px');
    } else {
      this._renderer.setStyle(this._elementRef.nativeElement.childNodes[0], 'top', (this.res.cord?.y - 100) + 'px');
    }
  }

  private _setCustomMark(condition: boolean, value: number): void {
    this._customMark = condition ? value : null;
    this.form.get('absense').patchValue(null);
    this.form.get('isVerbalFormed').patchValue(null);
    this.form.get('leveledMark').patchValue(null);
    this.form.get('rating').disable();
    this.form.get('customMark').setValue(this._customMark);
    this._onChangeMark();
  }

  private _resetFieldsForCustomMark(): void {
    this.absense = null;
    this.customReleased = false;
    this.customNotCertified = false;
    this.customAccepted = false;
    this.customLearned = false;
    this.customNotRated = false;
  }

  private _onChangeMark(): void {
    this.journalChanged.emit({mark: this.form.value, type: ''});
    this.visible.emit({coordinates: this._coordinates, isOutside: true});
  }

  private _saveCheckbox(): void {
    this.absense = this.data?.absense;
    this.customNotRated = this.data?.customMark === 4;
    this.customReleased = this.data?.customMark === 0;
    this.customNotCertified = this.data?.customMark === 1;
    this.customAccepted = this.data?.customMark === 2;
    this.customNotAccepted = this.data?.customMark === 5;
  }

  private _absentCheck(event: number): void {
    this.form.get('absense').patchValue(event);
    this.form.get('isVerbalFormed').patchValue(null);
    this.form.get('leveledMark').patchValue(null);
    this.form.get('rating').reset();
    this._onChangeMark();
  }
}
