import {Injectable} from '@angular/core';
import {ColumnCreationParams} from '@nit-core/models';

@Injectable({
  providedIn: 'root'
})
export class ColumnCreationService {
  params: ColumnCreationParams;
  firstLessonDate: string;
  thematicDates: string[] = [];
  summaryDates: string[] = [];

  saveParams(params: ColumnCreationParams): void {
    this.params = params;
    const from = params.journalDay.averageMarkSettings?.from;
    const to = params.journalDay.averageMarkSettings?.to;

    const paramsToSave = {
      ...this.params,
      firstLessonDate: this.firstLessonDate,
      thematicDates: this.thematicDates,
      summaryDates: this.summaryDates,
      currentSchedule: params.currentSchedule,
      journalDay: {
        ...params.journalDay,
        date: params.journalDay.date ? this._buildCorrectISOString(params.journalDay.date) : null,
        averageMarkSettings: params.journalDay.averageMarkSettings ? {
          ...params.journalDay.averageMarkSettings,
          from: from ? this._buildCorrectISOString(from) : null,
          to: to ? this._buildCorrectISOString(to) : null,
        } : null
      },
    };

    sessionStorage.setItem('thematic-settings', JSON.stringify(paramsToSave));
  }

  getParams(): ColumnCreationParams {
    const params = JSON.parse(sessionStorage.getItem('thematic-settings'));

    return params || this.params;
  }

  saveThematicDates(data: {thematicDates: string[], summaryDates: string[], firstLessonDate?: string}) {
    this.thematicDates.push(...data.thematicDates);
    this.summaryDates.push(...data.summaryDates);

    if (data.firstLessonDate) {
      this.firstLessonDate = data.firstLessonDate;
    }
  }

  private _buildCorrectISOString(date: Date): string {
    const time = 'T00:00:00.000Z';
    const year = date.getFullYear();
    const month = date.getMonth() + 1 > 9 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1);
    const day = date.getDate() > 9 ? date.getDate() : ('0' + date.getDate());

    return `${year}-${month}-${day}${time}`;
  }
}
