import {Injectable} from '@angular/core';
import {RestService} from '@nit-core/services/global/http-services/rest.service';
import {GeneralAchievement} from '@nit-core/models/achievement';

@Injectable({providedIn: 'root'})
export class GeneralAchievementService extends RestService<GeneralAchievement> {
  constructor() {
    super(GeneralAchievement, '/general-achievements', 'general-achievements');
  }
}
