import {JournalColumnType} from '@nit-core/models';
import {DynamicComponentDate} from '@nit-core/models/journal-item';

export class JournalTableService {
  liberated: boolean = false;
  notCertified: boolean = false;
  credited: boolean = false;
  studied: boolean = false;
  notRated: boolean = false;
  havePresence: boolean = false;
  notAccepted: boolean = false;

  resetAvailableCustomMark(): void {
    this.havePresence = false;
    this.liberated = false;
    this.notCertified = false;
    this.notAccepted = false;
    this.credited = false;
    this.studied = false;
    this.notRated = false;
  }

  checkboxCheckingToOpen(data: DynamicComponentDate): void {
    if (data) {
      if (data.subject?.toLowerCase() === 'фізична культура') {
        if (data.type === JournalColumnType.Day) {
          this.havePresence = true;
          this.liberated = true;

          return;
        }

        if (data.type === JournalColumnType.Thematic || data.type === JournalColumnType.Semester ||
          data.type === JournalColumnType.Year || data.type === JournalColumnType.SemesterChanged ||
          data.type === JournalColumnType.YearChanged) {
          this.liberated = true;
          this.notCertified = true;
          this.notAccepted = true;
          this.credited = true;

          return;
        }

        if (data.type === JournalColumnType.Custom) {
          this.liberated = true;
          this.notCertified = true;
          this.credited = true;
          this.notAccepted = true;
          this.notRated = true;

          return;
        }

        if (data.type === JournalColumnType.LearningOutcome) {
          this.notCertified = true;
          this.credited = true;
          this.notAccepted = true;
          this.liberated = true;

          return;
        }

        if (data.type === JournalColumnType.DPA) {
          this.liberated = true;

          return;
        }
      } else if (data.subject?.toLowerCase() === 'українська мова') {
        if (data.type === JournalColumnType.Day) {
          this.havePresence = true;

          return;
        }

        if (data.type === JournalColumnType.Thematic || data.type === JournalColumnType.Semester ||
          data.type === JournalColumnType.Year || data.type === JournalColumnType.SemesterChanged ||
          data.type === JournalColumnType.YearChanged || data.type === JournalColumnType.CustomSemester ||
          data.type === JournalColumnType.CustomYear || data.type === JournalColumnType.CustomSemesterChanged ||
          data.type === JournalColumnType.CustomYearChanged) {
          this.liberated = true;
          this.notCertified = true;
          this.notAccepted = true;
          this.credited = true;
          this.studied = true;

          return;
        }

        if (data.type === JournalColumnType.Custom) {
          this.notCertified = true;
          this.notAccepted = true;
          this.credited = true;
          this.notRated = true;

          return;
        }

        if (data.type === JournalColumnType.DPA) {
          this.liberated = true;

          return;
        }

        if (data.type === JournalColumnType.LearningOutcome) {
          this.notCertified = true;
          this.credited = true;
          this.notAccepted = true;
          this.liberated = true;
          this.studied = true;

          return;
        }
      } else {
        if (data.type === JournalColumnType.Day) {
          this.havePresence = true;

          return;
        }

        if (data.type === JournalColumnType.Thematic || data.type === JournalColumnType.Semester ||
          data.type === JournalColumnType.Year || data.type === JournalColumnType.SemesterChanged ||
          data.type === JournalColumnType.YearChanged || data.type === JournalColumnType.CustomSemester ||
          data.type === JournalColumnType.CustomYear || data.type === JournalColumnType.CustomSemesterChanged ||
          data.type === JournalColumnType.CustomYearChanged) {
          this.liberated = true;
          this.notCertified = true;
          this.notAccepted = true;
          this.credited = true;

          return;
        }

        if (data.type === JournalColumnType.Custom) {
          this.notCertified = true;
          this.credited = true;
          this.notAccepted = true;
          this.notRated = true;

          return;
        }

        if (data.type === JournalColumnType.DPA) {
          this.liberated = true;

          return;
        }

        if (data.type === JournalColumnType.LearningOutcome) {
          this.notCertified = true;
          this.credited = true;
          this.notAccepted = true;
          this.liberated = true;

          return;
        }

        if (data.isExcursion) {
          this.havePresence = true;

          return;
        }
      }
    }
  }
}
