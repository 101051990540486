<div #journalTable 
     id="journal-table"
     class="table-wrapper"
     [ngStyle]="{'overflow-y' : scrollMobileTable || !isMobile ? 'auto' : 'hidden'}" 
     (scroll)="closeDialog()">
  <table>
    <thead>
      <tr *ngIf="students">
        <th class="student">Учні</th>
        <th *ngFor="let journalDay of customJournalDays; let yIndex = index; trackBy: trackBy"
            class="date"
            [class.nush]="journalDay.type === 17 || journalDay.type === 18"
            [class.thematic-custom]="journalDay.type !== 0 && journalDay.type !== 17 && journalDay.type !== 18"
            [class.average-rating]="journalDay.type | allowedColumn : [9, 11, 12, 19]"
            [class.active]="(journalDay.date | timestamp) === (journal.currentDay | timestamp)">
          <nit-journal-item-header [data]="{
                                     isHasPermission: headerPermissions[yIndex],
                                     isAdmin: isAdmin,
                                     isTeacherInClass: journalDay.teacherId === this.userId || isJournalTeacher,
                                     isJournalTeacher: isJournalTeacher,
                                     isClassTeacher: isClassTeacher,
                                     journalType: journalType,
                                     journalDay: journalDay,
                                     journal: journal,
                                     canBeDeleted: journalDay.canBeDeleted,
                                     isEmpty: journalDay.isEmpty,
                                     canTransfer: journalDay.canTransfer,
                                     activeDate: (journalDay.date | timestamp) === (journal.currentDay | timestamp),
                                   }"
                                   [journalId]="journal.id"
                                   [classId]="journal.classId"
                                   [currentSchedule]="currentSchedule"
                                   [isDisable]="isDisable"
                                   [selectedTab]="selectedTab"
                                   [isClassProfile]="isClassProfile"
                                   [isFifthOrGreater]="isFifthOrGreater"
                                   [hasCompetences]="hasCompetences"
                                   [updatedCustomColumnName]="customName"
                                   (nitAddEditJournalColumnModal)="$event?.journalIndex >= 0 ? nitAddEditJournalColumnModal.open($event) : nitDeleteJournalColumnModal.open($event)"
                                   (nitTransferringDataToColumnModal)="nitTransferringDataToColumn.openTransfer($event)"
                                   (openSemesterAverageScoreModal)="nitAddEditJournalColumnModal.open({
                                     columnId: journalDay.averageColumnId,
                                     journalDay: journalDay,
                                     journalType: journalDay.type,
                                     includeInDecision: journalDay.includeInDecision,
                                     semester: currentSchedule.semester
                                   })"
                                   (openAddGroupOfResultsModal)="openAddGroupOfResultsModal($event)"
                                   (getAverageScore)="getAverageScore(journalDay.averageColumnId, journalDay.id, journalDay.type, $event)"
                                   (created)="journalChanged.emit()"
                                   (createYearColumn)="addDeleteYearColumn(journalDay)"
                                   (moved)="moveColumn($event)">
          </nit-journal-item-header>
        </th>
        <th *ngFor="let eDay of rendererEmptyDays; trackBy: trackBy;"
            class="date disabled">
        </th>
      </tr>
    </thead>

    <tbody *ngIf="journal && students">
      <tr *ngFor="let student of students; trackBy: trackBy; let xIndex = index">
        <td class="student">
          <nit-student-name [index]="xIndex"
                            [studentName]="isMobile ? student.shortName : student.lastName + ' ' + student.firstName"
                            [educationType]="student?.educationType">
          </nit-student-name>
        </td>

        <ng-container *ngIf="journal">
          <td *ngFor="let journalDay of customJournalDays; let yIndex = index; trackBy: trackBy;"
              class="date"
              [class.thematic-custom]="journalDay.type !== 0 && journalDay.type !== 17 && journalDay.type !== 18"
              [class.average-rating]="journalDay.type | allowedColumn : [9, 11, 12, 19]"
              [class.dropped-out]="journalDay.blurredForStudents[student.userId]"
              [class.active]="(journalDay.date | timestamp) === (journal.currentDay | timestamp) && journalDay.type === 0">
            <nit-journal-item #journalItems
                              [data]="{
                                journal: journal,
                                journalDay: journalDay,
                                activeDay: journal?.currentDay,
                                classId: journal?.classId,
                                class: journal?.className,
                                scheduleId: journal?.scheduleId,
                                student: student,
                                subject: journal?.subject,
                                showAverage: journalDay.type | allowedColumn : [9, 11, 12, 19],
                                userAverageMark: groupedAverageMark.get([journalDay.averageColumnId + '', student.userId, '0']),
                                isHasPermission: journalDay.type !== 9 ? cellPermissions[yIndex] : false,
                                isBlurred: journalDay.blurredForStudents[student.userId],
                                coordinates: [xIndex, yIndex],
                                mark: groupedMarks.get([journalDay.id + '', student.userId, '0']) ? groupedMarks.get([journalDay.id + '', student.userId, '0']) : null,
                                hasReminder: groupedMarks.get([journalDay.id + '', student.userId, '0'])?.absense === null ? groupedReminders.get([journalDay.id + '', student.userId, '0']) : null,
                              }"
                              [actualSettings]="actualSettings"
                              (created)="createRating($event)"
                              (cellClicked)="currentAddBlock(xIndex, yIndex)">
            </nit-journal-item>
          </td>
        </ng-container>

        <td *ngFor="let eDay of rendererEmptyDays; trackBy: trackBy;"
            class="date disabled">
        </td>
      </tr>
    </tbody>
  </table>
</div>

<ng-template NitAddRating></ng-template>

<nit-add-edit-journal-column-modal #nitAddEditJournalColumnModal
                                   [currentSchedule]="currentSchedule"
                                   [journalDays]="journal.journalDay"
                                   (submitted)="addUpdateDeleteColumn($event, true)">
</nit-add-edit-journal-column-modal>

<nit-add-group-of-results-modal #addGroupOfResultsModal
                                [journalId]="journal.id"
                                [isJournalTeacher]="isJournalTeacher"
                                [currentSchedule]="currentSchedule"
                                (refresh)="journalChanged.emit()">
</nit-add-group-of-results-modal>

<nit-delete-journal-column-modal #nitDeleteJournalColumnModal
                                 (deleteSubmitted)="addUpdateDeleteColumn($event, false)">
</nit-delete-journal-column-modal>

<nit-transferring-data-to-column-modal #nitTransferringDataToColumn
                                       [semester]="currentSchedule?.semester"
                                       [actualSettings]="actualSettings"
                                       (transferringSubmitted)="$event.reason ?
                                         sendBatchGradesAndReason($event) : transferringJournalColumn($event)">
</nit-transferring-data-to-column-modal>
