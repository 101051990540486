import {
  AbsenceType,
  CustomMark,
  JournalColumnType,
  LeveledAssessmentType
} from './../global/domain/enums';
import {JournalDay} from './journal-day';
import {UserSubject} from './profile';

export class ChildJournal {
  journalDays: ChildJournalDay[];
  subjects: UserSubject[];
  activeDay: Date;

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.journalDays = item.journalDays ? item.journalDays.map(x => new JournalDay(x)) : [];
    this.subjects = item.subjects.length ? item.subjects.map(subject => new UserSubject(subject)) : [];
  }
}


export class ChildJournalDay extends JournalDay {
  subjects: ChildJournalSubject[];

  constructor(item: any = {}) {
    super(item);
    this.subjects = item.subjects.length ? item.subjects.map((subject: any) =>
      new ChildJournalSubject(subject)) : [];
  }
}

export class ChildJournalSubject {
  subject: string;
  marks: ChildMark [];

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.marks = item.marks.length ? item.marks.map((mark: any) => new ChildMark(mark)) : [];
  }
}

export class ChildMark {
  id: string;
  rating?: string;
  description?: string;
  absense?: AbsenceType;
  date: Date;
  dateString: string;
  type: JournalColumnType;
  subject: string;
  status?: string | null;
  customMark?: CustomMark;
  isVerbalFormed: boolean | null;
  leveledMark?: LeveledAssessmentType;
  hint?: string;
  hintDescription?: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.date = item?.date ? new Date(item.date) : null;
    this.dateString = this.date?.toString();
    this.customMark = item.customMark ? item.customMark.map(x => CustomMark[x]) : null;
    this.isVerbalFormed = item.isVerbalFormed ? true : null;
    this.leveledMark = item.leveledMark ? item.leveledMark.map(x => LeveledAssessmentType[x]) : null;
  }
}
