import {NgModule} from '@angular/core';
import {AddressPipe} from '@nit-core/pipes/address.pipe';
import {AuthImagePipe} from '@nit-core/pipes/auth.image.pipe';
import {DayOfWeekPipe} from './../pipes/day-of-week.pipe';
import {DaysFromNumbersPipe} from '@nit-core/pipes/day-from-number.pipe';
import {ShortNamePipe} from '@nit-core/pipes/short-name.pipe';
import {PhoneFormatPipe} from '../pipes/phone-format.pipe';
import {UrlPipe} from '@nit-core/pipes/url.pipe';
import {SubjectsPipe} from '@nit-core/pipes/subjects.pipe';
import {TransferPipe} from '@nit-core/pipes/transfer.pipe';
import {MarkTypesPipe} from '@nit-core/pipes/mark-types.pipe';
import {HighlightPipe} from '@nit-core/pipes/highlight.pipe';
import {PluralizePipe} from '@nit-core/pipes/pluralize.pipe';
import {TimeViewPipe} from '@nit-core/pipes/time-view.pipe';
import {DatePipe} from '@angular/common';
import {TransformTypePipe} from 'src/app/private/pages/chat/components/message-input/file-type.pipe';
import {DateWithDotsPipe} from '@nit-core/pipes/date-with-dots.pipe';
import {TrimTagsPipe} from '@nit-core/pipes/trim-tags.pipe';
import {StudentFormErrorsPipe} from '@nit-core/pipes/student-form-errors.pipe';
import {NamePipeCirclePipe} from '@nit-core/pipes/name-pipe-circle.pipe';
import {IncomingCallStylePipe} from '@nit-core/pipes/incoming-call-style.pipe';
import {SelectedPopoverValuePipe} from '@nit-core/pipes/selected-popover-value.pipe';
import {DecisionDisabledStatePipe} from '@nit-core/pipes/decision-disabled-state.pipe';
import {ClassesByNumberPipe} from '@nit-core/pipes/classes-by-number.pipe';
import {UserNameDialogPipe} from '@nit-core/pipes/user-name-dialog.pipe';
import {RoomNamePipe} from '@nit-core/pipes/room-name.pipe';
import {MimetypeSplitterPipe} from '@nit-core/pipes/mimetype-splitter.pipe';
import {EllypsizePipe} from '@nit-core/pipes/ellypsize.pipe';
import {HasMarkDataPipe} from '@nit-core/pipes/has-mark-data.pipe';
import {MonthNameGenitivePipe} from '@nit-core/pipes/month-names-genitive.pipe';
import {MessageTextPipe} from '@nit-core/pipes/message-text.pipe';
import {PositionNamePipe} from '@nit-core/pipes/position-name.pipe';
import {TimestampPipe} from '@nit-core/pipes/timestamp.pipe';
import {CalendarLogoPipe} from '@nit-core/pipes/calendar-logo.pipe';
import {DaysFromTodayPipe} from '@nit-core/pipes/days-from-today.pipe';
import {HealthGroupPipe} from '@nit-core/pipes/health-group.pipe';
import {StringTimePipe} from '@nit-core/pipes/string-time.pipe';
import {MatchQuestionAnswersPipe} from '@nit-core/pipes/match-question-answers.pipe';
import {CoursesContextItemsPipe} from '@nit-core/pipes/courses-context-items.pipe';
import {AllowedJournalColumnPipe} from '@nit-core/pipes/allowed-journal-column.pipe';

@NgModule({
  declarations: [
    AddressPipe,
    AllowedJournalColumnPipe,
    AuthImagePipe,
    DayOfWeekPipe,
    DateWithDotsPipe,
    DaysFromNumbersPipe,
    HighlightPipe,
    MarkTypesPipe,
    PhoneFormatPipe,
    ShortNamePipe,
    SubjectsPipe,
    TimeViewPipe,
    TransferPipe,
    UrlPipe,
    PluralizePipe,
    TransformTypePipe,
    TrimTagsPipe,
    EllypsizePipe,
    StudentFormErrorsPipe,
    NamePipeCirclePipe,
    IncomingCallStylePipe,
    SelectedPopoverValuePipe,
    StringTimePipe,
    DecisionDisabledStatePipe,
    ClassesByNumberPipe,
    UserNameDialogPipe,
    PositionNamePipe,
    RoomNamePipe,
    MimetypeSplitterPipe,
    HasMarkDataPipe,
    MonthNameGenitivePipe,
    MessageTextPipe,
    MonthNameGenitivePipe,
    TimestampPipe,
    CalendarLogoPipe,
    DaysFromTodayPipe,
    HealthGroupPipe,
    MatchQuestionAnswersPipe,
    CoursesContextItemsPipe
  ],
  exports: [
    AddressPipe,
    AllowedJournalColumnPipe,
    AuthImagePipe,
    DayOfWeekPipe,
    DateWithDotsPipe,
    DaysFromNumbersPipe,
    HighlightPipe,
    MarkTypesPipe,
    PhoneFormatPipe,
    PluralizePipe,
    SubjectsPipe,
    ShortNamePipe,
    TimeViewPipe,
    TransferPipe,
    TransformTypePipe,
    UrlPipe,
    TrimTagsPipe,
    EllypsizePipe,
    StudentFormErrorsPipe,
    NamePipeCirclePipe,
    IncomingCallStylePipe,
    SelectedPopoverValuePipe,
    PositionNamePipe,
    DecisionDisabledStatePipe,
    ClassesByNumberPipe,
    StringTimePipe,
    UserNameDialogPipe,
    RoomNamePipe,
    MimetypeSplitterPipe,
    HasMarkDataPipe,
    MonthNameGenitivePipe,
    TimestampPipe,
    MonthNameGenitivePipe,
    MessageTextPipe,
    CalendarLogoPipe,
    DaysFromTodayPipe,
    HealthGroupPipe,
    MatchQuestionAnswersPipe,
    CoursesContextItemsPipe
  ],
  providers: [
    DatePipe
  ]
})
export class NitSharedModule { }
