import {ApiResponse, Profile, SchoolInfo} from '@nit-models';
import {Injectable} from '@angular/core';
import {HTTPOptions, RestService} from '@nit-core/services/global/http-services/rest.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {UserRole} from '@nit-core/global/domain/enums';
import {TotalFinalMark} from '@nit-core/models/total-final-mark';
import {Decision, NushDecision} from '@nit-core/models';
import {UserPermissions} from '@nit-core/models/userPermissions';
import {VisitAccounting} from '@nit-core/models/visit-accounting';
import {PresenceTotalBody} from '@nit-core/models/presence';
import {AuthService} from '@nit-auth';
import {CreateTeacherBody} from '@nit-core/models/teacher';
import {Achievement} from '@nit-core/models/achievement';

@Injectable({providedIn: 'root'})
export class UserService extends RestService<Profile> {

  currentUser$: BehaviorSubject<Profile> = new BehaviorSubject<Profile>(null);
  currentSchoolInfo$: BehaviorSubject<SchoolInfo> = new BehaviorSubject<SchoolInfo>(null);
  userPermissions$: BehaviorSubject<UserPermissions> = new BehaviorSubject<UserPermissions>(null);
  isChild$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isClassTeacher$: BehaviorSubject<SchoolInfo> = new BehaviorSubject<SchoolInfo>(null);

  constructor(private readonly _authService: AuthService) {
    super(Profile, '/users/', 'users');
  }

  getClassTeacherClassIds(): string[] {
    return this.userPermissions$.getValue().permissions.find(permissionsSet => {
      return permissionsSet.schoolId === this._authService.getUserSchoolId() && !permissionsSet.isChild;
    })?.classIds || [];
  }

  getRelations(id: string, type: number): Observable<ApiResponse<Profile>> {
    return this.httpGetAll(`${this.apiHref()}/${id}/relations/${type}`, {alias: 'profile-relations', asUserId: id});
  }

  updateImage(id: string, data: FormData): Observable<any> {
    return this.httpPut(`${this.apiHref()}/${id}/image`, data);
  }

  removeImage(id: string): Observable<any> {
    return this.httpDelete(`${this.apiHref()}/${id}/image`, {asUserId: id});
  }

  createChildrenWithParents(data: any): Observable<any> {
    return this.httpPost(`${this.apiHref()}/children-with-parents`, data);
  }

  createAdminOrTeacher(data: CreateTeacherBody): Observable<{id: string}> {
    return this.httpPost(`${this.apiHref()}/worker`, data);
  }

  changeRole(userId: string, userRole: UserRole): Observable<any> {
    return this.httpPut(`${this.apiHref()}/change-role`, {userId, userRole});
  }

  addParentsToChildren(data: any): Observable<any> {
    return this.httpPost(`${this.apiHref()}/add-parents-to-children`, data);
  }

  getFinalRating(userId: string, childId?: string, schoolId?: number): Observable<TotalFinalMark> {
    return this.httpGet<TotalFinalMark>(`${this.apiHref()}/${userId}/final-rating`, {
      asUserId: childId,
      asUserSchoolId: schoolId,
      factory: TotalFinalMark
    });
  }

  getUserVisitingTotal(visitingFilter: VisitAccounting, userId: string, asUserId?: string, schoolId?: number): Observable<ApiResponse<PresenceTotalBody>> {
    return this.httpGetAll(`${this.apiHref()}/${userId}/total-visiting`, {
      query: this.normalizeParams(visitingFilter),
      asUserId: asUserId,
      asUserSchoolId: schoolId,
      factory: PresenceTotalBody
    });
  }

  getDecision(userId: string, childId?: string, schoolId?: number): Observable<Decision> {
    return this.httpGet<Decision>(`${this.apiHref()}/${userId}/decision`, {
      asUserId: childId,
      asUserSchoolId: schoolId,
      factory: Decision
    });
  }

  getNushDecision(userId: string, childId?: string, schoolId?: number): Observable<NushDecision> {
    return this.httpGet<NushDecision>(`${this.apiHref()}/${userId}/nush-decision`, {
      asUserId: childId,
      asUserSchoolId: schoolId,
      factory: Decision
    });
  }

  getPermissions(userId: string, asDeleted: boolean = false): Observable<UserPermissions> {
    return this.httpGet(`${this.apiHref()}/${userId}/permissions?asDeleted=${asDeleted}`, {factory: UserPermissions});
  }

  getMyPermissions(): Observable<UserPermissions> {
    return this.httpGet(`${this.apiHref()}/my-permissions`, {factory: UserPermissions});
  }

  getSchoolInfo(userId: string, schoolId?: number, differentId?: boolean, schoolForSupport?: number): Observable<SchoolInfo> {
    const options: HTTPOptions = {
      factory: SchoolInfo,
      asUserId: differentId ? this._authService.userId : userId,
      asUserSchoolId: schoolId
    };

    if (schoolForSupport) {
      options.query = {schoolId: `${schoolForSupport}`};
    }

    return this.httpGet(`${this.apiHref()}/${userId}/school-info`, options);
  }

  deleteFromSchool(userId: string): Observable<void> {
    return this.httpDelete(`${this.apiHref()}/${userId}/deactivate`);
  }

  getAchievementByUser(userId: string, options: HTTPOptions): Observable<Achievement> {
    return this.httpGet<Achievement>(`${this.apiHref()}/${userId}/achievements`, options);
  }
}
