import {Decision} from './decision';
import {SemesterSettings} from './nush-rule';

export class NushDecision extends Decision {
  classId: string;
  firstName: string;
  fullName: string;
  id: string;
  lastName: string;
  leaveTransferId?: string;
  middleName: string;
  userId: string;
  rule: NushDecisionRule;

  constructor(item: any = {}) {
    super(item);
    Object.assign(this, item);
    if (item.rule) this.rule = new NushDecisionRule(item.rule);
  }
}

export class NushDecisionRule {
  certificate: NushDecisionCertificate;
  firstSemesterSettings: SemesterSettings;
  secondSemesterSettings: SemesterSettings;

  constructor(item: any = {}) {
    Object.assign(this, item);
    if (item.certificate) this.certificate = new NushDecisionCertificate(item.certificate);
  }
}

export class NushDecisionCertificate {
  domains: NushDecisionDomain[];
  name: string;
  id: string;
  hasSchedules: boolean;
  generalCharacteristics: NushDecisionLearningOutcome[];

  constructor(item: any = {}) {
    Object.assign(this, item);
    if (item.generalCharacteristics) this.generalCharacteristics = item.generalCharacteristics.map(x => new NushDecisionLearningOutcome(x));
    if (item.domains) this.domains = item.domains.map(x => new NushDecisionDomain(x));
  }
}

export class NushDecisionDomain {
  id?: string;
  name: string;
  subjects: NushDecisionSubject[];

  constructor(item: any = {}) {
    Object.assign(this, item);
    if (item.subjects) this.subjects = item.subjects.map(x => new NushDecisionSubject(x));
  }
}

export class NushDecisionSubject {
  subjectId: string;
  name: string;
  learningOutcomes: NushDecisionLearningOutcome[];
  domainId?: string;
  isFds?: boolean;

  constructor(item: any = {}) {
    Object.assign(this, item);
    if (item.learningOutcomes) this.learningOutcomes = item.learningOutcomes.map(x => new NushDecisionLearningOutcome(x));
  }
}

export class NushDecisionLearningOutcome {
  id: string;
  isImmutable?: boolean;
  name: string;
  groupName?: string;
  groupId?: string;
  isGroupSelected?: boolean;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class GroupedOutcomesDictionary {
  [key: string]: GroupOfOutcomes;

  constructor(item: any = {}) {
    Object.assign(this, item);
    for (const key of item) {
      this[key] = item[key] ? new GroupOfOutcomes(item[key]) : null;
    }
  }
}

export class GroupOfOutcomes {
  groupName: string;
  groupId: string;
  isEvaluated?: boolean;
  groupedAchievements: NushDecisionLearningOutcome[];

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.groupedAchievements = item.groupedAchievements.length ?
      item.groupedAchievements.map(ach => new NushDecisionLearningOutcome(ach)): [];
  }
}
