import * as GroupBy from 'group-array';

export class GroupedArray<T = any> {
  constructor(values: T[] = [], keys: string[] = []) {
    Object.assign(this, GroupBy(values, keys));
  }

  get?(is: string | string[], obj: this = this): any {
    if (typeof is === 'string') {
      return this.get(is.split('.'), obj);
    }
    if (is.length === 0) {
      return obj;
    }
    if (!obj[is[0]]) {
      return undefined;
    }

    return this.get(is.slice(1), obj[is[0]]);
  }
}
